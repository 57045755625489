
import Vue from 'vue';
import TaskManagerHistoric from '@/resources/task-manager/components/TaskManagerHistoric.vue';
import { SchedulesStatusLogs } from '@/types';

export default Vue.extend({
  name: 'TaskManagerHistoricModal',
  components: {
    TaskManagerHistoric,
  },
  props: {
    showModal: {
      type: Boolean as () => boolean,
      default: false,
    },
    schedulerLogs: {
      type: Array as () => SchedulesStatusLogs[],
      required: true,
    },
  },
  data() {
    return {
      header: this.$t('taskManager.historic.header'),
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean): void {
        this.$emit('update:showModal', value);
      },
    },
  },
});
