var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("p", { staticClass: "description" }, [
        _vm._v(" " + _vm._s(_vm.$t("taskManager.historic.description")) + " "),
      ]),
      _c("Timeline", {
        staticClass: "historic",
        style: { height: "600px" },
        attrs: { value: _vm.schedulerLogs },
        scopedSlots: _vm._u([
          {
            key: "opposite",
            fn: function (slotProps) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-center flex-col h-full",
                  },
                  [
                    _c(
                      "small",
                      {
                        staticClass:
                          "p-text-secondary block text-center color-black87 text-sm",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatDate(slotProps.item.executedAt, "LT")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "small",
                      { staticClass: "p-text-secondary r-text-muted text-xs" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatDate(slotProps.item.executedAt, "L")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "marker",
            fn: function (slotProps) {
              return [
                _c(
                  "span",
                  {
                    staticClass:
                      "h-6 w-6 p-1 rounded-full flex justify-center items-center shadow-md",
                  },
                  [
                    slotProps.item.statusCode === "200" ||
                    slotProps.item.statusCode === "201"
                      ? _c("i", {
                          staticClass: "icon-r-circle-success-filled success",
                        })
                      : _c("i", {
                          staticClass: "icon-r-circle-error-filled error",
                        }),
                  ]
                ),
              ]
            },
          },
          {
            key: "content",
            fn: function (slotProps) {
              return [
                _c("div", { staticClass: "content" }, [
                  _c("div"),
                  _c("span", { staticClass: "color-black87 text-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          slotProps.item.statusCode === "200" ||
                            slotProps.item.statusCode === "201"
                            ? _vm.$t("success")
                            : _vm.$t("fail")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }