
import Vue from 'vue';
import Timeline from 'primevue/timeline';
import { SchedulesStatusLogs } from '@/types';

export default Vue.extend({
  name: 'TaskManagerHistoric',
  components: {
    Timeline,
  },
  props: {
    schedulerLogs: {
      type: Array as () => SchedulesStatusLogs[],
      required: true,
    },
  },
  methods: {
    formatDate(date: string, format: string): string {
      return this.$moment(date).parseZone().format(format);
    },
  },
});
