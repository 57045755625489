var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { maxWidth: "800px", minWidth: "800px" },
      attrs: {
        visible: _vm.displayModal,
        "content-style": { padding: "0", margin: "24px 1.125rem" },
        header: _vm.header,
        modal: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.displayModal = $event
        },
      },
    },
    [
      _c("TaskManagerHistoric", {
        attrs: { "scheduler-logs": _vm.schedulerLogs },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }